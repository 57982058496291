/* responsive ******************************************************************************/
@media (max-width: 1599px) {

}
/* responsive ******************************************************************************/
@media (max-width: 1399px) {
    .button-box.margin-top .glow-btn {
        width: 30%;
    }
    .partners-box .step-box .content-box {
        min-height: 200px;
    }
    #bestOnline .bestOnline-part.bg .bestOnline-box .text-box p {
        font-size: 18px;
    }

}

/* responsive ******************************************************************************/
@media (max-width: 1299px) {
    #homeBanner .homeBanner-box .box1 .text-box p.sub-text {
        font-size: 26px;
    }
}
/* responsive ******************************************************************************/
@media (max-width: 1199px) {
    .homeBanner-box .sub-text{
        font-size: 40px;
    }
    .step-box {
        padding: 50px 20px;
    }
    .glow-btn {
        font-size: 16px;
    }
    #games .img-box img {
        max-width: 100%;
    }
    #games .games-box1 .text-box p, #games .games-box2 .text-box p, #games .games-box3 .text-box p {
        font-size: 15px;
    }
    .bg {
        padding: 50px 0;
    }
    .section-heading h2 {
        font-size: 25px;
    }
    
    #homeBanner .homeBanner-box .box1 .text-box p.sub-text {
        font-size: 24px;
    }
    #bestOnline .bestOnline-part.bg .bestOnline-box .text-box h5 {
        font-size: 26px;
    }
    #works .works-box .step-box .text-box {
        font-size: 16px;
    }
    #works .works-box .text-box p {
        font-size: 18px;
    }
}
/* responsive ******************************************************************************/
@media (max-width: 1080px) {
    footer#footer .footer-box .top-box nav ul li a {
        font-size: 18px;
    }
    #footer .footer-part {
        padding-bottom: 30px;
    }
    #feature .feature-box .step-box .text-box p {
        font-size: 20px;
    }
    #feature .feature-box .step-box {
        padding: 0px 5px 20px;
    }
    #feature .feature-box .step-box .img-box img {
        /* max-width: 77%; */
        height: 60px;
        width: 60px;
    }
    
}
/* responsive ******************************************************************************/
@media (max-width: 991px) {
    .container {
        max-width: 100%;
    }
    .section-heading h2 {
        font-size: 34px;
    }
    #header {
        padding-top: 30px;
    }
    #header .button-box {
        margin-right: 40px;
    }
    #homeBanner .homeBanner-part .homeBanner-box .row {
        row-gap: 80px;
    }
    #homeBanner .homeBanner-box .box1 .text-box p.sub-text {
        font-size: 20px;
    }
    #homeBanner .homeBanner-box .box1 .text-box p {
        font-size: 16px;
    }
    #FAQ .FAQ-part .FAQ-box .accordion-item .accordion-button {
        font-size: 18px;
    }
    #FAQ .FAQ-part .FAQ-box .row {
        margin: 0;
    }
    footer#footer .footer-box .top-box .contact-box .box {
        justify-content: center;
    }
    #games .img-box img {
        max-width: 90%;
    }
    #feature .feature-box .step-box .text-box p {
        font-size: 17px;
    }
}
/* responsive ******************************************************************************/
@media (max-width: 767px) {
    /* header responsive */
    #header {
        padding-right: 40px;
        padding-bottom: 10px;
    }
    #header .main-nav nav {
        position: fixed;
        top: 0;
        left: -50%;
        width: 100%;
        height: 100vh;
        background: #000;
        opacity: 0;
        visibility: hidden;
        transition: .5s all ease-in-out;
        z-index: 111;
        padding-top: 60px;
    }
    #header .main-nav nav.show {
        left: 0;
        opacity: 1;
        visibility: visible;
    }
    #header .main-nav nav ul li {
        display: block;
        text-align: left;
        padding: 5px 10px;
    }
    #header .main-nav .click-menu,
    #header .main-nav .cancel-menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    #header .main-nav .cancel-menu {
        position: fixed;
        top: 20px;
        left: -100%;
        max-width: 30px;
        width: 100%;
        text-align: center;
        z-index: 222;
        transition: .5s;
        opacity: 0;
        visibility: hidden;
    }
    #header .main-nav .cancel-menu.show {
        opacity: 1;
        visibility: visible;
        left: calc(100% - 50px);
    }
    #header .main-nav {
        flex-direction: row-reverse;
    }
    #header .button-box {
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
        row-gap: 20px;
    }
    #header .glow-btn {
        width: 30%;
    }
    img.arrow-img {
        max-width: 10%;
    }
    .mobile {
        display: block !important;
    }
    .desktop {
        display: none !important;
    }
    .button-box.margin-top .glow-btn {
        width: 40%;
    }
    .section-heading h2 {
        font-size: 25px;
    }
    .section-heading p {
        font-size: 16px;
    }
    #homeBanner .homeBanner-part .homeBanner-box {
        padding: 50px 0;
    }
    #homeBanner .homeBanner-part .homeBanner-box .row{
        row-gap: 15px;
        flex-direction: column-reverse;
    }
    #homeBanner .homeBanner-box .box1 .text-box h1 {
        text-align: center;
    }
    #homeBanner .homeBanner-part .homeBanner-box .box.box2 .img-box img {
        max-width: 50%;
    }
    #homeBanner .homeBanner-part .homeBanner-box .box.box2 .img-box {
        display: flex;
        justify-content: center;
    }
    #homeBanner .homeBanner-box .box1 .button-box {
        display: block;
    }
    #bestOnline .bestOnline-part.bg .bestOnline-box .text-box p {
        font-size: 16px;
    }
    #works .works-box .row {
        margin-bottom: 50px;
        margin-top: 49px;
        row-gap: 40px;
    }
    #games  .img-box {
        max-width: 55%;
        margin: 0 auto;
    }
    .box-border p {
        font-size: 15px;
    }
    .partners-box .row {
        row-gap: 35px;
    }
    .partners-box .step-box {
        padding: 5px 22px 6px;
    }
    #feature .feature-box  .crick-text p {
        font-size: 15px;
    }
    #why .why-box .section-heading {
        margin-bottom: 80px;
    }
    .col-md-3 {
        min-width: 100px;
    }
    #why .why-box .step-box {
        padding: 70px 30px 30px;
    }
    footer#footer .footer-box .top-box nav ul li a {
        font-size: 14px;
    }
    #works .works-box .section-heading {
        text-align: center;
        margin-bottom: -20px;
    }

    #feature .feature-box .step-box .img-box img {
        /* max-width: 77%; */
        height: 75px;
        width: 75px;
        margin-top: 5px;
    }
        #feature .feature-box .step-box {
            padding: 0px 5px 20px;
        }
        #feature .feature-box .step-box .text-box p {
            font-size: 16px;
        }
    
}
/* responsive ******************************************************************************/
@media (max-width: 575px) {
    #header .logo-box img {
        max-width: 180px;
    }
    #works .works-box  .col-sm-6 {
        width: 50%;
    }
    #why .why-box .col-12 {
        width: 50%;
    }
    #why .why-box .step-box .text-box p {
        font-size: 14px;
    }
    .partners-box .row {
        row-gap: 40px;
    }
    #works .works-box .text-box p {
        font-size: 15px;
    }
    .section-heading h2 {
        font-size: 25px;
    }
    #games .games-box1 .text-box p, #games .games-box2 .text-box p, #games .games-box3 .text-box p {
        font-size: 15px;
        text-align: center;
    }
    /* #feature .feature-box .step-box {
        min-height: 30vh;
    } */
    #FAQ .FAQ-part .FAQ-box .accordion-body {
        font-size: 12px;
    }
    footer#footer .footer-box .top-box .contact-box .box {
        justify-content: start;
    }
    footer#footer .footer-box .top-box nav ul {
        flex-direction: column;
        row-gap: 15px;
    }
    footer#footer .footer-box .top-box nav ul li {
        text-align: center;
    }
    #footer .top-box .contact-box {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
    }
    #footer .top-box .call-box {
        padding: 7px;
    }
    #games .games-box1 .row, #games .games-box3 .row{
        flex-direction: column-reverse;
    }
    #FAQ .FAQ-part .FAQ-box .accordion-item .accordion-button span {
        height: 7px;
        width: 7px;
    }
    #FAQ .FAQ-part .FAQ-box .row {
        padding: 20px 0px  0;
    }
    
}
/* responsive ******************************************************************************/
@media (max-width: 479px) {
  
    .bg {
        padding: 40px 0;
    }
    .button-box.margin-top {
        column-gap: 10px;
        margin-top: 0;
    }
    .box-border {
        padding: 20px 25px;
    }
    .button-box.margin-top .glow-btn {
        width: 100%;
        padding: 11px 12px;
        font-size: 14px;
    }
    .section-heading h2 {
        font-size: 22px;
    }
    .section-heading p {
        font-size: 13px;
    }
    .img-left {
        left: -10px;
        bottom: 0;
    }
    #header .glow-btn {
        width: 50%;
    }
    #works .works-box .section-heading {
        text-align: center;
        margin-bottom: -30px;
    }

    #homeBanner .homeBanner-box .box1 .text-box p.sub-text {
        font-size: 18px;
    }
    #homeBanner .homeBanner-box .box1 .text-box h1 {
        margin: 10px 0;
        font-size: 30px;
    }
    #homeBanner .homeBanner-box .box1 .text-box p br{
        display: block;
    }
    #homeBanner .homeBanner-part .homeBanner-box .box.box2 .img-box img {
        max-width: 70%;
    }
    #homeBanner .homeBanner-part .homeBanner-box .row {
        row-gap: 30px;
        flex-direction: column-reverse;
    }
    #bestOnline .bestOnline-part.bg .bestOnline-box .text-box h5 {
        font-size: 20px;
    }
    #bestOnline .bestOnline-part.bg .bestOnline-box .text-box p {
        font-size: 14px;
    }
    #why .why-box .row {
        row-gap: 60px;
    }
    #why .why-box .col-12 {
        width: 100%;
    }
    #works .works-box .text-box p {
        font-size: 16px;
    }
    #works .works-box .step-box .text-box {
        font-size: 14px;
    }
    .partners-box .step-box .content-box {
        min-height: 150px;
    }
    .partners-box .step-box {
        padding: 20px 20px;
    }
    #FAQ .FAQ-part .FAQ-box .accordion-body {
        padding: 1rem 1.25rem;
        font-size: 14px;
    }
    #FAQ .FAQ-part .FAQ-box .accordion-item .accordion-button {
        font-size: 16px;
        padding: 13px 0;
    }
    footer#footer .footer-box .top-box .contact-box {
        row-gap: 20px;
        padding: 20px 20px;
    }
    footer#footer .footer-box .top-box .contact-box .box img {
        height: 35px;
        width: 25px;
    }
    #footer .end-box .text-box p {
        font-size: 14px;
    }
    footer#footer .footer-box .top-box .contact-box .box  p span {
        font-size: 14px;
    }
    #footer .top-box .mail-box .mail-text  p, #footer .top-box .call-box .call-text  p {
        font-size: 17px;
    }
    #footer .top-box .mail-box {
        justify-content: left;
    }
    #feature .feature-box .step-box .heading p {
        font-size: 16px;
    }
    #feature .feature-box .step-box {
        padding: 0px 20px 20px;
    }
    #footer .top-box .logo-box img {
        max-width: 255px;
    }
    #FAQ .FAQ-part .FAQ-box .accordion-item .accordion-button span {
        height: 5px;
        width: 5px;
    }
    #feature .feature-box .step-box .text-box p {
        font-size: 17px;
    }
}
/* responsive ******************************************************************************/
@media (max-width: 375px) {
    .section-heading {
        margin-bottom: 30px;
    }
    #works .works-box .text-box p {
        font-size: 14px;
    }
    .partners-box .col-6{
        width: 100%;
    }
    .partners-box .button-box.margin-top .glow-btn {
        width: 100%;
    }
    #footer .top-box .mail-box,   #footer .top-box .call-box  {
        width: 305px;
    }
    
    #footer .top-box .mail-box .mail-text  p, #footer .top-box .call-box .call-text  p {
        font-size: 13px;
    }
    .section-heading h2 {
        font-size: 20px;
    }
    #feature .feature-box .step-box .img-box img {
        /* max-width: 77%; */
        height: 75px;
        width: 75px;
        display: flex;
        /* margin-top: 25px; */
    }
    #feature .feature-box .step-box .img-box {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        
    }
    #feature .feature-box  .step-box .crick-box {
        display: flex;
        flex-direction: column;
    }
    #FAQ .FAQ-part .FAQ-box .accordion-item .accordion-button {
        font-size: 13px;
    }
    #FAQ span {
        padding-right: 5px;
    }
}