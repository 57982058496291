/* common css ******************************************************************************/

@font-face {
    font-family: "GolosText-Medium";
    src: url('../font/GolosText/GolosText-Medium.ttf');
}

:root {
    --white: #fff;
    --red: #AA3528;
    --green: #2CA940;
    --dark-text:#425864;
    --body-bg:#12191D;
}

.red{
    background: var(--red) !important;
}
.green{
    background: var(--green) !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}


body {
    background-color: var(--body-bg);
    max-width: 100%;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    line-height: 1.2;
    font-weight: normal;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
    margin-bottom: 0;
}

h1 {
    font-size: 55px;
}

h2 {
    font-size: 44px;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 28px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 20px;
}

p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 1;
    /* margin-bottom: 25px; */
    text-transform: capitalize;
    color: var(--white);
}

p:last-child {
    margin-bottom: 0;
}

.small {
    font-size: 16px;
}

ul,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    text-transform: capitalize;
    display: inline-block;
    line-height: 1.2;
    color: inherit;
    font-family: inherit;
}

span {
    display: inline-block;
    color: var(--white);
    font-size: 20px;
}

img,
video {
    max-width: 100%;
}

.btn-common{
    border-radius: 5px;
}


.buttons a {
    color:var(--white);
}
.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-group:focus-visible{
    outline: none !important;
    border-radius: none !important;
}
/* Header css  **********************************/

#header {
    background: transparent;
    padding: 30px 10px;
    transition: 0.5s all;
    position: relative;
    width: 100%;
}

#header .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#header .logo-box img {
    width: 100%;
}

#header .logo-box {
    display: flex;
    text-align: center;
    align-items: center;
    column-gap: 3px;
}

header .header-box .left-nav {
    display: flex;
    align-items: center;
}

#header .main-nav .stocks {
    display: flex;
    column-gap: 5px;
    padding-right: 29px;
    border-right: 2px solid rgba(168, 171, 173, 1);
    cursor: pointer;
    /* width: 100%; */
}

#header .main-nav .stocks p {
    color: #999999;
    font-size: 13px;
    line-height: 0.8;
}

#header .main-nav .stocks span {
    display: inline-block;
    color: var(--white);
    font-size: 13px;
}

header .header-box .right-nav {
    display: flex;
    align-items: center;
}
header .header-box .right-nav .btn-common {
    padding: 10px 18px;
    cursor: pointer;
}
header .header-box .right-nav .btn-common span{
    font-size: 16px;
    font-weight: 500;
}
#header .stocks {
    position: relative;
    margin-right: 20px;
}


#header .main-nav {
    margin-left: 20px;
}


#header .button-box {
    display: flex;
    margin-left: 20px;
    padding-top: 0;
    column-gap: 20px;
}

#header .main-nav nav ul li {
    display: inline-block;
    position: relative;
    margin-right: 20px;
}

#header .main-nav nav ul li a {
    color: var(--white);
    padding: 5px;
    font-size: 18px;
}

#header .glow-btn {
    padding: 5px 27px;
    background-color: var(--green);
    font-family: "GolosText-Medium";
    height: 32px;
}



/* Left sidebar css ************************** */
.left-sidebar {
    width: 145px;
    border-right: 2px solid;
    background: #12191d;
    flex-shrink: 0;
}

.left-sidebar .history,
.left-sidebar .openbtn {
    cursor: pointer;
    background-color: #12191d;
    padding: 26px 25px;
    border: none;
    width: 100%;
}
.left-sidebar button span{
    color: var(--dark-text);
    font-size: 16px;
    font-weight: 500;
}

/* totalPortfolioPopup css */

.totalPortfolioPopup {
    min-width: 337px;
    max-width: 337px;
    position: relative;
    z-index: 999;
    background-color: #12191D;
    overflow-x: hidden;
    transition: 0.5s;
    display: none;
}

.totalPortfolioPopup .sidebar-header {
    margin: 0 15px;
}
.totalPortfolioPopup .dropdown-btn {
    padding: 0px 0px 0px 7px;
    text-decoration: none;
    font-size: 15px;
    background-color: #425864 !important;
    color: white;
    display: flex;
    border: none;
    justify-content: space-between;
    align-items: center;
    background: none;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    outline: none;
}
.totalPortfolioPopup .portfolio {
    overflow-y: auto;
    height: calc(100vh - 190px);
}
#trandingHistoryData::-webkit-scrollbar,
#scrollbar::-webkit-scrollbar {
    width: 8px;
}

#trandingHistoryData::-webkit-scrollbar-track ,
#scrollbar::-webkit-scrollbar-track {
    background: #675f5f;
    border-radius: 50px;
}

#trandingHistoryData::-webkit-scrollbar-thumb ,
#scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}
.totalPortfolioPopup a:hover {
    color: #f1f1f1;
}

.totalPortfolioPopup .closebtn {
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    font-size: 25px;
    color: var(--white);
    display: block;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
}
/* trandingHistoryData css  */

.trandingHistoryData {
    width: 100%;
    position: relative;
    z-index: 999;
    background-color: #12191D;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
    display: none;
    padding: 12px;
}

.trandingHistoryData .portfolio table {
    color: var(--white);
    width: 100%;
}

.trandingHistoryData .portfolio table th {
    color: #55595d;
    border-bottom: 1px solid #1B2226;
    padding-bottom: 10px;
    padding-left: 20px;
}

.trandingHistoryData .portfolio table td {
    padding-top: 17px;
    padding-left: 20px;
}

.trandingHistoryData .portfolio table td span.loss {
    color: var(--red);
}

.trandingHistoryData .portfolio table td span.profit {
    color: var(--green);
}

.trandingHistoryData .portfolio table td img {
    margin-right: 5px;
}
.trandingHistoryData .sidebar-header {
    background-color: #172025;
    padding:0 20px;
    border-radius: 5px;
}
.trandingHistoryData p {
    border-radius: 5px;
    font-size: 16px;
}
.trandingHistoryData .closebtn {
    font-size: 33px;
    color: white;
    cursor: pointer;
}

#trandingHistoryData tbody tr{
    padding: 5px 15px !important;
}

#trandingHistoryData tbody {
    display: block;
    height: 145px;
    overflow: auto;
}
#trandingHistoryData thead, #trandingHistoryData tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
#trandingHistoryData thead {
    width: calc( 100% - 1em )
}

.fa-caret-down {
    float: right;
    padding-right: 8px;
}

.portfolio .stocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    margin: 0px 17px;
    border-bottom: 2px solid;
}

.portfolio .stocks .time {
    display: flex;
    flex-direction: column;
}

.portfolio .stocks .time p {
    padding: 0 !important;
    font-size: 11px !important;
    color: #425864;
}

.portfolio .stocks .time span {
    padding: 0 !important;
    font-size: 13px !important;
}

.portfolio .stocks .country-stock {
    display: flex;
    align-items: center;
}

.portfolio .stocks .country-stock img {
    max-width: 100%;
}

.portfolio .stocks .country-stock span {
    font-size: 14px;
    margin-left: 6px;
}

.portfolio .stocks .profit span {
    font-size: 14px;
    color: var(--red);
}

.portfolio .stocks.three .profit span {
    font-size: 14px;
    color: var(--green);
}

.portfolio .stocks.five .profit span {
    font-size: 14px;
    color: var(--green);
}

.portfolio-box .left-sidebar .totalPortfolioPopup p {
    font-size: 15px;
    padding: 0 0 10px 22px;
}

.portfolio-box {
    display: flex;
    padding-right: 20px;
}


.portfolio-box .buttons span {
    text-transform: uppercase;
}
.portfolio-box .buttons .btn-common{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-family: "GolosText-Medium";
    cursor: pointer;
}
.portfolio-box .buttons #heigh {
    background-color: var(--green);
    margin-bottom: 10px;
}

.portfolio-box .buttons #low {
    background-color: var(--red);
}

/* Chart section css  */

.main-chart {
    flex-grow: 1;
    display: flex;
}
.charts {
    flex-grow: 1;
}
.chart_history_part {
    width: 100%;
}
.chart-rightsidebar {
    display: flex;
}
.tv-lightweight-charts {
    height: 100% !important;
    width: auto !important;
}

.tv-lightweight-charts>* {
    width: auto !important;
}

canvas{
    width: 100% !important;
    height: 100% !important;
}

/* Right sidebar  **********************/
.right-sidebar {
    z-index: 1;
    width: 160px;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}
.right-sidebar .buttons {
    margin-top: auto;
}
.amount-sub-block h5 {
    color: white;
    font-size: 13px;
    margin-bottom: 7px;
}

.amount-sub-block {
    background-color: #373e50;
    margin: 10px 0;
    padding: 10px;
}

.amount-sub-block p {
    font-size: 18px;
}

.profit-section {
    margin: 10px 0;
}
.profit-section p {
    color: white;
    font-size: 13px;
    text-align: center;
}

.profit-section h3 {
    color: var(--green);
    font-size: 30px;
    text-align: center;
    margin: 10px 0;
}

.profit-section h5 {
    color: var(--green);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.amount-block-price.amount-block-price {
    padding: 0;
}

.amount-sub-block .amount-inner {
    padding: 10px 0 10px 10px;
}

.amount-block-button {
    display: grid;
    justify-content: space-between;
}

.amount-block-button button:hover,
.amount-block-button button:active,
.btn:first-child:active {
    color: #fff;
    border: none;
}

.amount-block-button button {
    padding: 0 10px;
    color: #fff;
    width: 50%;
    border: 0 !important;
}

.main-nav {
    display: flex;
    flex-wrap: wrap;
}

.currency-box-wrapper .modal-body {
    background-color: #141b20;
}

.currency-box-wrapper .modal-item .stocks {
    background-color: #000000;
    border-radius: 5px;
    border: 1px solid #373e50;
    display: flex;
    padding: 20px 10px;
}

.main-nav .icon img {
    max-width: 35px;
    width: 100%;
}

.main-nav .country-img {
    border-radius: 50%;
}

.modal-dialog.currency-box-wrapper {
    max-width: 620px;
    width: 100%;
    left: 10%;
    top: 90px;
    margin: 0;
}

.currency-box-wrapper .modal-item {
    width: 33.33%;
}

.currency-box-wrapper .modal-item .stocks p,
.currency-box-wrapper .modal-item .stocks span {
    font-size: 15px;
}



img.close-icon {
    max-width: 14px;
    width: 100%;
    height: 10px;
    object-fit: contain;
    position: absolute;
    right: 5px;
}

.stocks.one.selected {
    border-bottom: 2px solid rgba(168, 171, 173, 1);
}

.amount-block-price {
    display: flex;
    justify-content: space-between;
}


.close-btn {
    background-color: #dcdcdc !important;
    color: black !important;
    padding: 4px 8px;
    border-radius: 10px;
    font-size: 14px !important;
    width: 50%;
    text-align: center;
}

.portfolio .profit .loss
{
    color: var(--red) !important;
}

.portfolio .profit .profit{
    color: var(--green) !important;
}