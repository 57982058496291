body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chart-background {
  position: relative;
  background-image: url('./assets/images/Layer2.png'); /* Replace with the path to your image */
  background-size: cover;  /* Make the image cover the entire container */
  background-position: center;
  width: 100%;
  height: 100%;
}

a#tv-attr-logo {
  --fill: none !important;
  --stroke: none !important;
  position: none !important;
  left: none !important;
  bottom: none !important;
  height: none !important;
  width: none !important;
  margin: none !important;
  padding: none !important;
  border: none !important;
  z-index: none !important;
}